import { 
  all, 
  call, 
  takeEvery, 
  put
  // select 
} from 'redux-saga/effects'
import { uploadBase64ImageforBackgroundRemoval,uploadPhotoboothImage } from 'services/virtual/general'
import { message } from 'antd'
import actions from './actions'

export function* UPLOAD_IMAGE(data) {
  try {
    // const state = yield select()
    // const {
    //   virtualUser: { accessToken },
    // } = state
    const result = yield call(uploadBase64ImageforBackgroundRemoval, { imageData: data.payload.imageString })

    if(result.status === 200)
    {
      yield put({
        type: actions.SET_STATE,
        payload: {
          transparentImage: result.data.fileUrl,
          imageProcessing: false
        },
      })
    }
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_PHOTOBOOTH_IMAGE(data) {
  try {
    // const state = yield select()
    // const {
    //   photobooth: {imageSrc},
    // } = state
    yield call(uploadPhotoboothImage, { imageData: data.payload.imageString })

  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.PHOTOBOOTH_ANALYTICS, PHOTOBOOTH_ANALYTICS)
    takeEvery(actions.UPLOAD_IMAGE, UPLOAD_IMAGE),
    takeEvery(actions.UPLOAD_PHOTOBOOTH_IMAGE, UPLOAD_PHOTOBOOTH_IMAGE)
  ])
}
