import actions from './actions'

const initialState = {
  stalls: {},
  current: 0,
  stallName: '',
  stallLogo: 'https://wiz365.s3.amazonaws.com/DefaultStall.jpg',
  savingStallDetails: false,
  isStallDesignerOpen: false,
  stallsLoading: false,
  selectedStall: {},
  scene: {},
  totalDocuments: 5,
  currentDocument: 0,
  allDocuments: {},
  theDownloadableCS: '',
  savingStall: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_STALL:
      return {
        ...state,
        stalls: {
          ...state.stalls,
          [action.payload.currentStall]: {
            ...state.stalls[action.payload.currentStall],
            [action.payload.key]: action.payload.actualload,
          },
        },
      }
    default:
      return state
  }
}
