import React from 'react'
import {Row, Col} from 'antd'
import '../itemcard.less'

class ItemDescription extends React.Component {

  render() {
    const {name, id, description, image } = this.props

    return (
      <Row>
        <Col span={10}>
          <img src={image} style={{width: "100%"}} alt={name} />
        </Col>
        <Col span={2} />
        <Col span={10}>
          <h2 key={id}>{name}</h2>
          <br />
          <h4>Product Specification</h4>
          <p><div dangerouslySetInnerHTML={{ __html: description }} /></p>
        </Col>
      </Row>
    )
  }
}

export default ItemDescription
