import React from 'react'
import { Row, Col, Avatar } from 'antd'
// import { push } from 'react-router-redux'
// import actions from 'redux/virtual/event/actions'

import './miniprofile.less'

class SpeakerMiniProfile extends React.Component {
  render() {
    const { speakerData } = this.props
    return (
      <div className="miniprofile">
        <Row>
          <Col span={24}>
            <Avatar
              src={speakerData.imageUrl}
              border
              borderColor="white"
              shape="square"
              size={150}
              style={{ borderRadius: '10px 10px 0px 0px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-center speakerName">
            <b>
              <h5>{speakerData.speakerName}</h5>
            </b>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-center speakerIntro">
            <h6>{speakerData.smallIntro}</h6>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SpeakerMiniProfile
