import actions from './actions'

const initialState = {
  messageBoxMessages: [],
  isMessageBoxVisible: false,
  isMessageBoxTickerVisible: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_MESSAGE:
      return {
        ...state,
        "messageBoxMessages": state.messageBoxMessages.concat(action.payload.toAdd),
      }
    default:
      return state
  }
}
