import { all, call, takeEvery, put } from 'redux-saga/effects'
import { getTotalRegistrations, getSceneData, getCurrentData } from 'services/virtual/analytics'
import { message } from 'antd'
import actions from './actions'

export function* GET_TOTAL_REGISTRATIONS() {
  try {
    const result = yield call(getTotalRegistrations)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          totalRegistrations: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_SCENE_DATA() {
  try {
    const result = yield call(getSceneData)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          sceneData: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_CURRENT_DATA() {
  try {
    const result = yield call(getCurrentData)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          currentData: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TOTAL_REGISTRATIONS, GET_TOTAL_REGISTRATIONS),
    takeEvery(actions.GET_SCENE_DATA, GET_SCENE_DATA),
    takeEvery(actions.GET_CURRENT_DATA, GET_CURRENT_DATA),
  ])
}
