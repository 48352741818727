import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { getGifts, redeemGifts } from 'services/virtual/eshop'
import { message } from 'antd'
import eventActions from 'redux/virtual/event/actions'
import actions from './actions'

export function* GET_GIFTS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
    const result = yield call(getGifts, { token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          giftsData: result.data,
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}
export function* REDEEM_GIFTS(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(redeemGifts, { token: accessToken, giftid: data.payload.giftid })
    if (result.status === 200) {
      yield put({
        type: eventActions.SET_STATE,
        payload: {
          isEShopOpen: false,
        },
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          redeemedComplete: true,
          loading: false,
          giftsData: {},
        },
      })
      message.success('Congratulations! Your Gift is successfully redeemed.', 5)
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_GIFTS, GET_GIFTS),
    takeEvery(actions.REDEEM_GIFTS, REDEEM_GIFTS),
  ])
}
