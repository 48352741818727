import React, {Component} from 'react'
import ChatHeader from '../../LayoutComponent/ChatHeader'
import ChatFooter from '../../LayoutComponent/ChatFooter'
import ChatContent from '../../LayoutComponent/ChatContent'

class ChatWindow extends Component {

  render() {
    return (
      <>
        <ChatHeader />
        <ChatContent />
        <ChatFooter />
      </>
    )
  }
}

export default ChatWindow