import React from 'react'
import { connect } from 'react-redux'
import Iframe from 'react-iframe'

import '../../socialwall.less'

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent, virtualUser })

@connect(mapStateToProps)
class TheFrame extends React.Component {

  render() {
    const {
      virtualEvent: {  whereByMeetURL, whereByMeetHostURL },
      virtualUser: { roles, name },
    } = this.props
    return (
      <div>
        <Iframe
          url={
            roles.includes('ROLE_ADMIN')
              ? `${whereByMeetHostURL}&displayName=${name}%20(Moderator)&leaveButton=off&roomIntegrations=on&recording=on`
              : `${whereByMeetURL}?displayName=${name}&leaveButton=off`
          }
          width="100%"
          height="calc(100vh - 120px)"
          loading="eager"
          className="theWherebyiFrame"
          display="initial"
          position="relative"
          frameborder="0"
          allow="camera; microphone; fullscreen; speaker; display-capture"
        />
      </div>
    )
  }
}

export default TheFrame
