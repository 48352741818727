import React from 'react'
import { connect } from 'react-redux'
import ReactAudioPlayer from 'react-audio-player';
import actions from 'redux/virtual/event/actions'
import './ticker.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const mapStateToProps = ({ virtualEvent,virtualUser }) => ({ virtualEvent,virtualUser })
@connect(mapStateToProps)
class LobbyMusic extends React.Component {
  toggleLobbyMusic = () => {
    const {dispatch, virtualEvent:{ isLobbyMusicPlaying }} = this.props
    dispatch({
      type: actions.SET_STATE,
      payload:{
        isLobbyMusicPlaying: !isLobbyMusicPlaying
      }
    })
  }

  render() {
    const {
      virtualEvent:{
        isLobbyMusicPlaying, 
        anyDollyPlaying,
        onDemandVideoOpen,
        scene: {stallStatus}},
      virtualUser: { 
        // isTutorialVideoVisible,
        isEntranceVideoVisible 
      },
      } = this.props
    console.log("when to mute")
    console.log(isLobbyMusicPlaying)
    console.log(isEntranceVideoVisible)
    console.log(onDemandVideoOpen)
    const theMuteClasses = isLobbyMusicPlaying ? "unmuted toggle-sound" : "unmuted toggle-sound sound-mute"
    return (
      stallStatus
      ?
        <>
          <ReactAudioPlayer
            src="resources/audio/lobbymusic.mp3"
            autoPlay
            loop
            muted={!isLobbyMusicPlaying || isEntranceVideoVisible || onDemandVideoOpen || anyDollyPlaying}
            // controls
          />
          <div className="lobbyMusicWidget">
            <div className={theMuteClasses} onClick={this.toggleLobbyMusic}>
              <div className="tooltip--left sound" data-tooltip="Turn On/Off Sound">
                <div className="sound--icon fa fa-volume-off" />
                <div className="sound--wave sound--wave_one" />
                <div className="sound--wave sound--wave_two" />
                <div className="sound--wave sound--wave_three" />
              </div>
            </div>
          </div>
        </>
      :
        ''
    )
  }
}

export default LobbyMusic