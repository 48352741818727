import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import actions from 'redux/virtual/aiphotobooth/actions'

import './overlay.less'

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,no-plusplus */
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

const backgrounds = {
  1: {
    imagePath: 'resources/images/backgrounds/background1.jpg',
    thumbnail: 'resources/images/backgrounds/background1.jpg',
  },
  2: {
    imagePath: 'resources/images/backgrounds/background2.jpg',
    thumbnail: 'resources/images/backgrounds/background2.jpg',
  },
  3: {
    imagePath: 'resources/images/backgrounds/background3.jpg',
    thumbnail: 'resources/images/backgrounds/background3.jpg',
  },
  4: {
    imagePath: 'resources/images/backgrounds/background4.jpg',
    thumbnail: 'resources/images/backgrounds/background4.jpg',
  },
  5: {
    imagePath: 'resources/images/backgrounds/background5.jpg',
    thumbnail: 'resources/images/backgrounds/background5.jpg',
  },
  6: {
    imagePath: 'resources/images/backgrounds/background6.jpg',
    thumbnail: 'resources/images/backgrounds/background6.jpg',
  },
  7: {
    imagePath: 'resources/images/backgrounds/background7.jpg',
    thumbnail: 'resources/images/backgrounds/background7.jpg',
  },
}

const mapStateToProps = ({ aiphotobooth }) => ({ aiphotobooth })

@connect(mapStateToProps)
class Background extends React.Component {
  state = {
    currentbackground: 1,
  }

  retake = () => {
    const {
      dispatch,
      aiphotobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  applyBackground = event => {
    const index = event.target.getAttribute('data-index')
    this.setState({ currentbackground: index })
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  goNext = () => {
    const {
      dispatch,
      aiphotobooth: { currentStep, transparentImage },
    } = this.props
    const { currentbackground } = this.state


    const canvas = document.createElement('canvas')
    canvas.id = 'backgroundImage'
    canvas.width = 1000
    canvas.height = 562
    const ctx = canvas.getContext('2d')

    const backgroundImage = new Image()
    backgroundImage.src = backgrounds[currentbackground].imagePath
    ctx.drawImage(backgroundImage, 0, 0)

    const image = new Image()
    image.setAttribute('crossorigin', 'anonymous');
    image.src = transparentImage
    image.onload = () => {
      ctx.drawImage(image, 0, 0)
      const finalImage = canvas.toDataURL()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          imageSrc: finalImage,
        },
      })

      const imgBlob = this.dataURLtoFile(finalImage,`${Date.now()}photobooth.png`)
      dispatch({
        type: actions.UPLOAD_PHOTOBOOTH_IMAGE,
        payload: {
          imageString: imgBlob
        },
      })
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
  }

  render() {
    const {
      aiphotobooth: { transparentImage,imageProcessing },
    } = this.props
    const { currentbackground } = this.state
    const slides = Object.entries(backgrounds).map(key => {
      return (
        <div>
          <img
            src={key[1].thumbnail}
            alt={`background${key[0]}`}
            data-index={key[0]}
            onClick={this.applyBackground}
            style={{ width: '100%' }}
          />
        </div>
      )
    })
    return (
      <>
        {/* <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center"> */}
        {imageProcessing ? <div className="loaderOverlay"><img src="resources/images/processingloading.gif" alt="loader" style={{ width: '100%', opacity: "0.8" }} /></div> : ''}
        <img
          src={backgrounds[currentbackground].imagePath}
          alt="backgroundImage"
          style={{ width: '100%' }}
        />
        {imageProcessing ? '' : <img src={transparentImage} alt="newImage" style={{ width: '100%' }} className="overlayimage" />}
        <Row justify="space-around" align="middle" style={{ marginTop: '5px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="primary" onClick={this.goNext} size="medium">
              Next &nbsp; <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '5px' }}>
          <Col span={24} className="containerSlider">
            <h4 style={{color: "white"}}>Select Background</h4>
            <Slider {...settings}>{slides}</Slider>
          </Col>
        </Row>

        
      </>
    )
  }
}

export default Background
