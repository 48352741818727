import React from 'react'
// import { Modal, Row, Col, Tooltip } from 'antd'
import Viewer from 'react-viewer';
import { connect } from 'react-redux'
// import { CloseCircleOutlined } from '@ant-design/icons'
// import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'

import './tutvideo.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class ImagePop extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        imagePopOpen: false
      },
    })
  }

  render() {
    const {
      virtualEvent: { imagePopOpen,imagePopURL },
    } = this.props
    return (
      <div>
        {/* <Modal
          visible={imagePopOpen}
          style={{
            // top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="imagePopModal"
          maskClosable
          mask
          // width="60rem"
          height="calc(100vh-10rem)"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.93)"}}
          closeIcon={<Tooltip title="Close Video"><CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} /></Tooltip>}
          destroyOnClose
        >
          <Row gutter={24} id="modalContent" className="modalContent">
            <Col span={24}>
              <img
                src={imagePopURL}
                style={{height:"100%"}}
                alt="Touch This"
              />
            </Col>
          </Row>
        </Modal> */}

        <Viewer
          visible={imagePopOpen}
          onClose={this.handleClose}
          images={[{src: imagePopURL, alt: ""}]}
          rotatable={false}
          scalable={false}
          onMaskClick={this.handleClose}
          drag={false}
          noNavbar
          noImgDetails
          changeable={false}
          // noToolbar
          // noFooter
          style={{paddingRight: 0}}
        />
      </div>
    )
  }
}

export default ImagePop
