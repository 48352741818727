import axios from 'axios'

/* eslint-disable no-unused-vars */
const superlocalURL = `https://localhost:443/api/`
const semiProductionURL = `https://4615-2405-201-500c-7088-813b-d223-2846-1ae4.ngrok.io/api/`
const productionURL = `https://cloudkonect.wiz365.io/api/`
const wiz365productURL = `https://awsfsi.wiz365.io/api/`
export default axios.create({
  baseURL: wiz365productURL,
})
