/* eslint-disable */
import React from 'react'
import {
  Modal,
  Row,
  Col,
  Skeleton, Tabs,
  Button,
} from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined, PlayCircleFilled } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
// import ViewSDKClient from './Pdfviewer'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

// import Scrollbars from 'react-custom-scrollbars'
import SessionList from './SessionList'

import './agenda.less'

/* eslint-disable prefer-destructuring,  no-unused-vars */
const { TabPane } = Tabs
const mapStateToProps = ({ virtualSettings, agenda, virtualEvent }) => ({
  virtualSettings,
  agenda,
  virtualEvent,
})

@connect(mapStateToProps)
class Agenda extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })

  // }
  state = {
    numPages: null,
  }

  // componentDidMount() {
  //   const viewSDKClient = new ViewSDKClient()
  //   viewSDKClient.ready().then(() => {
  //     /* Invoke file preview */
  //     /* By default the embed mode will be Full Window */
  //     viewSDKClient.previewFile('adobe-dc-view', {})
  //   })
  // }

  onError = err => {
    console.log('error in opening file')
    console.log(err)
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  initiateSession = () => {
    const {
      dispatch,
      agenda: { agendas },
      virtualEvent: { currenSceneAgenda },
    } = this.props
    const theBaseAgenda =
      Object.keys(agendas).length !== 0
        ? agendas[Object.keys(agendas)[0]]
        : currenSceneAgenda[Object.keys(currenSceneAgenda)[0]]
    const theBaseSession = theBaseAgenda.agendaItems[Object.keys(theBaseAgenda.agendaItems)[0]]
    const id = Object.keys(theBaseAgenda.agendaItems)[0]
    const scene = theBaseSession.assignedRoom
    const url = theBaseSession.url
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
        callScene: true,
        chatToggle: theBaseSession.chatToggle,
        pollingToggle: theBaseSession.pollingToggle,
        qandaToggle: theBaseSession.qandaToggle,
        reactionsToggle: theBaseSession.reactionsToggle,
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualEvent/SET_STATE',
      payload: {
        daywiseAgenda:''
      },
    })
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
  }
  getDayAgenda = (dayWiseAgendaArr) => {
    const {
      virtualSettings: { isVirtualMobileView },
      agenda: { loading },
    } = this.props
    if (dayWiseAgendaArr.length > 0) {
      return (
        <div
          key={dayWiseAgendaArr[0].id}
        >
          <Row style={{ background: '#6ec171' }}>
            <Col span={8} style={{ border: '1px solid white' }}>
              <p style={{ margin: '0' }}><b>Title</b></p>
            </Col>
            <Col span={8} style={{ border: '1px solid white' }}>
              <p style={{ margin: '0' }}><b>Speaker</b></p>

            </Col>
            <Col span={8} style={{ border: '1px solid white' }}>

            </Col>
          </Row>
          <SessionList
            sessions={dayWiseAgendaArr[0]?.agendaItems}
            isVirtualMobileView={isVirtualMobileView}
            loading={loading}
            agendaType="global"
          />
        </div>)
    }
    return ''
  }
  render() {
    const {
      virtualSettings: { isAgendaOpen, isVirtualMobileView },
      agenda: { agendas, loading },
      virtualEvent: { daywiseAgenda }
    } = this.props
    const { numPages } = this.state
    console.log('agendas==>', Object.values(agendas)?.filter(item => item.name === daywiseAgenda))
    const dayWiseAgendaArr = Object.values(agendas)?.filter(item => item?.name?.toLowerCase() === daywiseAgenda?.toLowerCase())




    const showcase = Object.entries(agendas)
      .map(key => {
        return (
          <TabPane
            tab={
              <span>
                {key[1].name}
                {/* <br />
                {key[1].date} */}
              </span>
            }
            key={key[0]}
          >
            <Row style={{ background: '#6ec171' }}>
              <Col span={8} style={{ border: '1px solid white' }}>
                <p style={{ margin: '0' }}><b>Title</b></p>
              </Col>
              <Col span={8} style={{ border: '1px solid white' }}>
                <p style={{ margin: '0' }}><b>Speaker</b></p>

              </Col>
              <Col span={8} style={{ border: '1px solid white' }}>

              </Col>
            </Row>
            <SessionList
              sessions={key[1].agendaItems}
              isVirtualMobileView={isVirtualMobileView}
              loading={loading}
              agendaType="global"
            />
          </TabPane>
        )
      })

    return (
      <div>
        <Modal
          visible={isAgendaOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="agendaModal"
          maskClosable={false}
          mask
          width="80vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={
            <CloseCircleOutlined
              style={{
                color: 'white',
                fontSize: '25px',
                width: '56px',
                height: '56px',
                left: '0px',
              }}
            />
          }
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.9)' }}
        // destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={6} className="text-center" />
            <Col span={12} className="text-center">
              <h3>{daywiseAgenda !== '' ? daywiseAgenda : 'Agenda'}</h3>
            </Col>
            <Col span={6} className="text-center" />
          </Row>
          <Row id="modalContent" className="modalContent">
            <Scrollbars style={{ height: 'calc(100vh - 20rem)' }}>
              <Row align="middle">
                <Col span={24} className="text-center">
                  <Skeleton loading={loading} active>
                    {daywiseAgenda !== '' ?
                      this.getDayAgenda(dayWiseAgendaArr)
                      :
                      <Tabs tabPosition={isVirtualMobileView ? 'top' : 'top'}>
                        {showcase}
                      </Tabs>
                    }
                  </Skeleton>

                </Col>
              </Row>
            </Scrollbars>
          </Row>

        </Modal >
      </div >
    )
  }
}

export default Agenda
