import { all, call, takeEvery, put,select } from 'redux-saga/effects'
import {
  getSavedData,
} from 'services/virtual/stalldesign'
import {
  getSelectedScene,
} from 'services/virtual/event'
import {
  writeData,
} from 'services/virtual/setup'
import { uploadFile } from 'services/virtual/general'
import { message } from 'antd'
import uuid from 'react-uuid'
import eventActions from '../event/actions'
import actions from './actions'

/* eslint-disable prefer-destructuring */
export function* EDIT_BRAND_LOGO(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          stallLogo: result.data.fileUrl,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Error occured in uploading the Image. Kindly Choose a normal Avatar as of now.',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* GET_STALL_OPTIONS() {
  try {
    const setupData = yield call(getSavedData)
    if (Object.keys(setupData.data.stalls).length > 0) {
      const final = setupData.data.stalls
      yield put({
        type: actions.SET_STATE,
        payload: {
          stalls: final,
          stallsLoading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* STALL_UPLOAD(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      const state = yield select()
      const {
        stallConfigurator: { scene,allDocuments },
      } = state

      if(data.payload.uploadtype === "documentupload")
      {
        const tempDocument = allDocuments
        tempDocument[data.payload.downloadIndex].fileURL = result.data.fileUrl
        yield put({
          type: actions.SET_STATE,
          payload: {
            allDocuments: tempDocument
          },
        })
      }
      else{
        const theSceneID = Object.keys(scene)[0]
        const tempState = scene
        if(tempState[theSceneID].clickSections[data.payload.downloadIndex].typeofPod === "video")
        {
          const tempID = Object.keys(tempState[theSceneID].clickSections[data.payload.downloadIndex].videoData)[0]
          tempState[theSceneID].clickSections[data.payload.downloadIndex].videoData[tempID].url = result.data.fileUrl
        }
        else if(tempState[theSceneID].clickSections[data.payload.downloadIndex].typeofPod === "widget")
        {
          if(tempState[theSceneID].clickSections[data.payload.downloadIndex].widget.widgetType === "image")
          {
            tempState[theSceneID].clickSections[data.payload.downloadIndex].widget.imageUrl = result.data.fileUrl
          }
        }

        yield put({
          type: actions.SET_STATE,
          payload: {
            scene: tempState
          },
        })
        
      }

      data.payload.onSuccess('ok')
      message.success('File Uploaded Successfully')
      
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    console.log(err)
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* INITIATE_SCENE() {
  try {
    const state = yield select()
    const {
      stallConfigurator: { 
        selectedStall,
        stallName,
        stallLogo 
      },
      virtualUser: {id,assignedScene,accessToken}
    } = state

    if(assignedScene.length === 0)
    {
      const sceneID = uuid()
      const collectedClickSections = {}
      let theDownloadableCS = ''
      Object.entries(selectedStall.clickSections).map(key => {
        if(key[1].typeofPod === "placeholder")
        {
          if(key[1].fileFormatPermitted.includes("mp4"))
          {
            collectedClickSections[uuid()] = {
              "name": key[1].name,
              "x": key[1].x,
              "y": key[1].y,
              "w": key[1].w,
              "h": key[1].h,
              "spawned": true,
              "navPath": "",
              "typeofPod": "video",
              "links": {},
              "dollyactive": false,
              "dollyurl": "",
              "boundation": false,
              "videoData": {
                [uuid()]: {
                  "timed": false,
                  "heading": "Video",
                  "icon": "vimeo",
                  "description": "Video",
                  "startTime": "",
                  "typeTitle": "Vimeo",
                  "endTime": "",
                  "type": "vimeo",
                  "url": ""
                }
              },
              "socialtables": {},
              "downloads": {},
              "productDemo": {},
              "widget": {
                "imageToggle": false,
                "imageUrl": "",
                "widgetType": "",
                "widgetData": {
                  "extraMessage": key[1].message,
                  "toBeSourced": true,
                  "typeofPod": key[1].typeofPod,
                  "fileFormatPermitted": key[1].fileFormatPermitted
                }
              },
              "sceneDetails": {},
              "chat": {
                "assignedExhibitors": []
              },
              "socialMedia": {
                "facebook": "",
                "instagram": "",
                "twitter": "",
                "linkedin": ""
              }
            }
          }
          else{
            collectedClickSections[uuid()] = {
              "name": key[1].name,
              "x": key[1].x,
              "y": key[1].y,
              "w": key[1].w,
              "h": key[1].h,
              "spawned": true,
              "navPath": "",
              "typeofPod": "widget",
              "canbeSpawned": false,
              "videoData": {},
              "links": {},
              "downloads": {},
              "chat": {
                "assignedExhibitors": []
              },
              "sceneDetails": {
                "sceneDisplayName": "",
                "sceneLogo": "",
                "sceneDescription": "",
                "sceneURL": ""
              },
              "productDemo": {},
              "widget": {
                "imageToggle": true,
                "imageUrl": "",
                "widgetType": "image",
                "widgetData": {
                  "extraMessage": key[1].message,
                  "toBeSourced": true,
                  "typeofPod": key[1].typeofPod,
                  "fileFormatPermitted": key[1].fileFormatPermitted
                }
              },
              "socialtables": {},
              "dollyactive": false,
              "dollyurl": "",
              "boundation": false,
              "socialMedia": {
                "facebook": "",
                "instagram": "",
                "twitter": "",
                "linkedin": ""
              }
            }
          }
        }
        else if(key[1].typeofPod === "downloadable")
        {
          theDownloadableCS = uuid()
          collectedClickSections[theDownloadableCS] = {
            "name": key[1].name,
            "x": key[1].x,
            "y": key[1].y,
            "w": key[1].w,
            "h": key[1].h,
            "spawned": true,
            "navPath": "",
            "typeofPod": "downloadable",
            "canbeSpawned": false,
            "videoData": {},
            "links": {},
            "downloads": {},
            "chat": {
              "assignedExhibitors": []
            },
            "sceneDetails": {
              "sceneDisplayName": "",
              "sceneLogo": "",
              "sceneDescription": "",
              "sceneURL": ""
            },
            "productDemo": {},
            "widget": {
              "imageToggle": false,
              "imageUrl": "",
              "widgetType": "",
              "widgetData": {
                "extraMessage": key[1].message,
                "toBeSourced": true,
                "typeofPod": key[1].typeofPod,
                "fileFormatPermitted": key[1].fileFormatPermitted
              }
            },
            "socialtables": {},
            "dollyactive": false,
            "dollyurl": "",
            "boundation": false,
            "socialMedia": {
              "facebook": "",
              "instagram": "",
              "twitter": "",
              "linkedin": ""
            }
          }
        }
        return ''
      })

      const defaultSceneData = {
        [sceneID] : {
          "id": sceneID,
          "name": stallName,
          "screenImageActualWidth": selectedStall.screenImageActualWidth,
          "screenImageActualHeight": selectedStall.screenImageActualHeight,
          "screenWidth": selectedStall.screenWidth,
          "screenHeight": selectedStall.screenHeight,
          "imageUrl": selectedStall.imageUrl,
          "imageName": null,
          "auditoriumStatus": false,
          "stallStatus": false,
          "socialTableStatus": false,
          "nextNav": "",
          "prevNav": "",
          "sceneToggle": true,
          "sceneToggleMessage": "",
          "roleRestriction": false,
          "roles": [],
          "roleRestrictionMessage": "",
          "bottomNav": {
            "enable": true,
            "chat": true,
            "meeting": false,
            "intro": false,
            "dropCard": false,
            "exhibitors": false,
            "socialMedia": true,
            "groupVideo": true
          },
          "clickSections": {
            [uuid()]: {
              "name": "Scene Intro",
              "x": "0",
              "y": "0",
              "w": "200",
              "h": "200",
              "spawned": false,
              "navPath": "",
              "typeofPod": "sceneDetails",
              "canbeSpawned": false,
              "videoData": {},
              "links": {},
              "downloads": {},
              "chat": {
                "assignedExhibitors": []
              },
              "sceneDetails": {
                "sceneDisplayName": stallName,
                "sceneLogo": stallLogo,
                "sceneDescription": "Description of stall",
                "sceneURL": "scene url"
              },
              "productDemo": {},
              "widget": {
                "imageToggle": false,
                "imageUrl": "",
                "widgetType": "",
                "widgetData": {
                  "toBeSourced": false
                }
              },
              "socialtables": {},
              "dollyactive": false,
              "dollyurl": "",
              "boundation": false,
              "socialMedia": {
                "facebook": "",
                "instagram": "",
                "twitter": "",
                "linkedin": ""
              }
            },
            [uuid()]: {
              "name": "Chat With",
              "x": "0",
              "y": "0",
              "w": "200",
              "h": "200",
              "spawned": false,
              "navPath": "",
              "typeofPod": "chat",
              "canbeSpawned": false,
              "videoData": {},
              "links": {},
              "downloads": {},
              "chat": {
                "assignedExhibitors": [id]
              },
              "sceneDetails": {
                "sceneDisplayName": "",
                "sceneLogo": "",
                "sceneDescription": "",
                "sceneURL": ""
              },
              "productDemo": {},
              "widget": {
                "imageToggle": false,
                "imageUrl": "",
                "widgetType": "",
                "widgetData": {
                  "toBeSourced": false
                }
              },
              "socialtables": {},
              "dollyactive": false,
              "dollyurl": "",
              "boundation": false,
              "socialMedia": {
                "facebook": "",
                "instagram": "",
                "twitter": "",
                "linkedin": ""
              }
            },
            [uuid()]: {
              "name": "Social Media",
              "x": "0",
              "y": "0",
              "w": "200",
              "h": "200",
              "spawned": false,
              "navPath": "",
              "typeofPod": "socialMedia",
              "canbeSpawned": false,
              "videoData": {},
              "links": {},
              "downloads": {},
              "chat": {
                "assignedExhibitors": []
              },
              "sceneDetails": {
                "sceneDisplayName": "",
                "sceneLogo": "",
                "sceneDescription": "",
                "sceneURL": ""
              },
              "productDemo": {},
              "widget": {
                "imageToggle": false,
                "imageUrl": "",
                "widgetType": "",
                "widgetData": {
                  "toBeSourced": true
                }
              },
              "socialtables": {},
              "dollyactive": false,
              "dollyurl": "",
              "boundation": false,
              "socialMedia": {
                "facebook": "",
                "instagram": "",
                "twitter": "",
                "linkedin": ""
              }
            }
          }
        },
      }

      console.log("Checking Default scene")
      console.log(defaultSceneData)

      const theFinalClickSections = { ...defaultSceneData[sceneID].clickSections, ...collectedClickSections }
      defaultSceneData[sceneID].clickSections = theFinalClickSections
      yield put({
        type: actions.SET_STATE,
        payload: {
          scene: defaultSceneData,
          theDownloadableCS
        },
      })
    }
    else{
      const result = yield call(getSelectedScene, { id: assignedScene[0], token: accessToken })
      console.log("in the new realm")
      console.log(result.data)
      if(result.status === 200)
      {
        let theDownloadableCS = ''
        let allDocuments = {}
        Object.entries(result.data.clickSections).map(key => {
          if(key[1].typeofPod === "downloadable")
          {
            theDownloadableCS = key[0]
            allDocuments = key[1].downloads
          }
          return ''
        })
        yield put({
          type: actions.SET_STATE,
          payload: {
            scene: {[result.data.id] : result.data},
            theDownloadableCS,
            allDocuments
          },
        })
      }
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_STALL() {
  try {
    const state = yield select()
    const {
      stallConfigurator: { scene,allDocuments,theDownloadableCS },
    } = state
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStall: true
      },
    })
    const theSceneID = Object.keys(scene)[0]
    const tempScene = {scenes: scene}
    tempScene.scenes[theSceneID].clickSections[theDownloadableCS].downloads = allDocuments

    if(typeof tempScene.scenes[theSceneID].nextNav === "object")
    {
      if(Object.prototype.hasOwnProperty.call(tempScene.scenes[theSceneID].nextNav, "id"))
      {
        tempScene.scenes[theSceneID].nextNav = tempScene.scenes[theSceneID].nextNav.id
      }
    }

    if(typeof tempScene.scenes[theSceneID].prevNav === "object")
    {
      if(Object.prototype.hasOwnProperty.call(tempScene.scenes[theSceneID].prevNav, "id"))
      {
        tempScene.scenes[theSceneID].prevNav = tempScene.scenes[theSceneID].prevNav.id
      }
    }
    const result = yield call(writeData, { load: tempScene })
    if (result.status === 200) {
      yield put({
        type: eventActions.FILTER_SCENE,
        payload: {
          id: theSceneID,
        },
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          isStallDesignerOpen: false,
          savingStall: false,
          scene: tempScene.scenes
        },
      })
      message.success('Stall Succesfully Saved.')
    }else {
      message.error('Error Occured in saving the stall. Kindly contact Admin.')
      yield put({
        type: actions.SET_STATE,
        payload: {
          savingStall: false
        },
      })
    }
  } catch (err) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStall: false
      },
    })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.EDIT_BRAND_LOGO, EDIT_BRAND_LOGO),
    takeEvery(actions.GET_STALL_OPTIONS, GET_STALL_OPTIONS),
    takeEvery(actions.STALL_UPLOAD, STALL_UPLOAD),
    takeEvery(actions.INITIATE_SCENE, INITIATE_SCENE),
    takeEvery(actions.SAVE_STALL, SAVE_STALL),
  ])
}
