import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'

const mapStateToProps = ({ virtualSettings }) => ({
  isMenuTop: virtualSettings.isVirtualMenuTop,
  isMobileMenuOpen: virtualSettings.isVirtualMobileMenuOpen,
  isMobileView: virtualSettings.isVirtualMobileView,
  isLightTheme: virtualSettings.isVirtualLightTheme,
})

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {
  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isVirtualMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  render() {
    const { isMenuTop, isMobileMenuOpen, isLightTheme } = this.props
    const BootstrappedMenu = () => {
      if (isMobile) {
        return (
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            <MenuLeft />
          </DrawerMenu>
        )
      }
      if (isMenuTop) {
        return <MenuTop />
      }
      return <MenuLeft />
    }

    return BootstrappedMenu()
  }
}

export default AppMenu
