import React from 'react'
import { Modal, Row, Col, Skeleton } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import SpeakerList from './SpeakerList'

import './speakers.less'

const mapStateToProps = ({ virtualSettings, agenda }) => ({ virtualSettings, agenda })

@connect(mapStateToProps)
class Speakers extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isSpeakersOpen',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isSpeakersOpen, isVirtualMobileView },
      agenda: { speakers, speakersloading },
    } = this.props

    return (
      <div>
        <Modal
          visible={isSpeakersOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="speakerModal"
          maskClosable={false}
          mask
          width="60rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ fontSize: '25px' }} />}
          // destroyOnClose
        >
          <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h1>Meet The Speakers</h1>
            </Col>
          </Row>
          <Row gutter={0} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
              <Col span={24}>
                <Skeleton loading={speakersloading} active>
                  <SpeakerList isVirtualMobileView={isVirtualMobileView} speakers={speakers} />
                </Skeleton>
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default Speakers
