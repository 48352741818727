import React from 'react'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Row, Col, Tabs } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/meetings/actions'
import MeetingList from './MeetingList'
import './allMeetings.less'

const { TabPane } = Tabs

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ meetings, virtualSettings }) => ({ meetings, virtualSettings }))
class AllMeetings extends React.Component {
  constructor(props) {
    super()
    const {
      virtualSettings: { isMeetingsOpen },
    } = props
    if (isMeetingsOpen) {
      props.dispatch({
        type: actions.GET_MEETING,
      })
    }
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isMeetingsOpen',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isMeetingsOpen },
    } = this.props
    return (
      <>
        <Modal
          visible={isMeetingsOpen}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal allMeets"
          width="60vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: '#fff', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>My Meeting Calendar</h3>
            </Col>
          </Row>
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 185px)">
              <Col span={24}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Meetings Scheduled" key="1">
                    <MeetingList meetingType="confirmed" />
                  </TabPane>
                  <TabPane tab="Meeting Request Sent" key="2">
                    <MeetingList meetingType="sent" />
                  </TabPane>
                  <TabPane tab="Meeting Requests Recieved" key="3">
                    <MeetingList meetingType="received" />
                  </TabPane>
                </Tabs>
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default AllMeetings
