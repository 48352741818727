import React from 'react'
import { Modal, Row, Col, Tooltip, Upload, Button, message } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/contest/actions'
import EntryViewer from './EntryViewer'
import GalleryViewer from './GalleryViewer'

import './contest.less'
import './heart.less'

/* eslint-disable  no-unused-vars,jsx-a11y/media-has-caption,no-plusplus */
const contestType = {
  photo: {
    description: 'jpeg / jpg / png',
    fileTypes: ['image/jpeg', 'image/png'],
  },
  video: {
    description: 'mp4',
    fileTypes: ['video/mp4'],
  },
  mix: {
    description: 'jpeg / jpg / png / mp4',
    fileTypes: ['image/jpeg', 'image/png', 'video/mp4'],
  },
}

const mapStateToProps = ({ contest }) => ({ contest })

@connect(mapStateToProps)
class Contest extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isContestOpen: false,
        currentContestID: '',
        currentContestType: '',
        currentMaxSize: '',
        contestEnteries: [],
        theImageSet: [],
        currentPage: 0,
        pageSize: 25,
        currentView: 'gallery',
        currentIndex: null,
      },
    })
  }

  beforeUpload = file => {
    const {
      contest: { currentContestType, currentMaxSize },
    } = this.props

    const isFileValid = contestType[currentContestType].fileTypes.includes(file.type)
    if (!isFileValid) {
      message.error(
        `Invalid Format. Only ${contestType[currentContestType].description} files are allowed`,
      )
    }
    const isValidFileSize = file.size / 1024 / 1024 < currentMaxSize // file size should be less than 655kb
    if (!isValidFileSize) {
      message.error(`File too large. Kindly upload the file that is less than ${currentMaxSize}MB`)
    }
    return isFileValid && isValidFileSize
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  resizeFile = (width, height, image) => {
    const thecanvas = document.createElement('canvas')
    thecanvas.id = `${Date.now()}-canvas`
    const ctx = thecanvas.getContext('2d')
    thecanvas.width = width
    thecanvas.height = height

    const wrh = image.height / image.width
    let newWidth = width
    let newHeight = newWidth * wrh
    if (newHeight > height) {
      newHeight = height
      newWidth = newHeight / wrh
    }
    const xOffset = newWidth < width ? (width - newWidth) / 2 : 0
    const yOffset = newHeight < height ? (height - newHeight) / 2 : 0

    ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight)
    return thecanvas.toDataURL()
  }

  resizeVideo = (width, height, video) => {
    const thecanvas = document.createElement('canvas')
    thecanvas.id = `${Date.now()}-canvas`
    const ctx = thecanvas.getContext('2d')
    thecanvas.width = width
    thecanvas.height = height

    const wrh = video.videoHeight / video.videoWidth
    let newWidth = width
    let newHeight = newWidth * wrh
    if (newHeight > height) {
      newHeight = height
      newWidth = newHeight / wrh
    }
    const xOffset = newWidth < width ? (width - newWidth) / 2 : 0
    const yOffset = newHeight < height ? (height - newHeight) / 2 : 0

    ctx.drawImage(video, xOffset, yOffset, newWidth, newHeight)
    return thecanvas.toDataURL()
  }

  fileUpload = ({ file, onSuccess, onProgress, onError, data }) => {
    const { dispatch, currentScene, index } = this.props
    const fileType = file.type

    if (fileType === 'video/mp4') {
      const thevideo = document.querySelector('#contest-temp-video')
      document.querySelector('#contest-temp-video').setAttribute('src', URL.createObjectURL(file))
      thevideo.load()
      thevideo.onloadedmetadata = () => {
        thevideo.currentTime = 1
      }
      thevideo.onseeked = () => {
        const finalThumbnail = this.resizeVideo(800, 450, thevideo)
        const thumbnail = this.dataURLtoFile(finalThumbnail, `${Date.now()}-thumbnail.png`)
        dispatch({
          type: actions.ENTRY_UPLOAD,
          payload: {
            file,
            thumbnail,
            clickSectionIndex: index,
            downloadIndex: data,
            uploadtype: 'fileupload',
            onSuccess,
            onError,
            onProgress,
            currentScene,
          },
        })
      }
    } else {
      const img = new Image()
      const convertedURL = URL.createObjectURL(file)
      img.src = convertedURL
      img.onload = () => {
        const finalThumbnail = this.resizeFile(300, 169, img)
        const thumbnail = this.dataURLtoFile(finalThumbnail, `${Date.now()}-thumbnail.png`)

        const finalImage = new Image()
        finalImage.src = convertedURL
        finalImage.onload = () => {
          const resizedFile = this.resizeFile(1920, 1080, finalImage)
          const finalFile = this.dataURLtoFile(resizedFile, `${Date.now()}-entry.png`)
          dispatch({
            type: actions.ENTRY_UPLOAD,
            payload: {
              file: finalFile,
              thumbnail,
              clickSectionIndex: index,
              downloadIndex: data,
              uploadtype: 'fileupload',
              onSuccess,
              onError,
              onProgress,
              currentScene,
            },
          })
        }
      }
    }
  }

  render() {
    const {
      contest: { isContestOpen, currentView },
    } = this.props

    return (
      <div>
        <Modal
          visible={isContestOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="contestModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="60vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>Contest Zone</h3>
            </Col>
          </Row>
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 100px)">
              {currentView === 'gallery' ? <GalleryViewer /> : <EntryViewer />}
            </Scrollbars>
          </Row>
          <Row id="modalFooter" justify="space-around" align="middle" className="modalFooter">
            <Col span={12} className="text-center">
              <h4>Upload your entry to particpate</h4>
              <h6>
                Disclaimer: Hope you all have practiced your skills well? Please upload relevant
                videos/ pictures to the context to enable us to do justice to your entries.
              </h6>
            </Col>
            <Col span={12} className="text-center">
              <Upload beforeUpload={this.beforeUpload} customRequest={this.fileUpload}>
                <Tooltip title="Upload Your Entry">
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Tooltip>
              </Upload>
              <video id="contest-temp-video" style={{ display: 'none' }}>
                <source type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default Contest
