import React from 'react'
import { Skeleton, notification } from 'antd'
import { AlertTwoTone } from '@ant-design/icons'
import { connect } from 'react-redux'
import ItemCard from '../ItemCard'
import 'antd/dist/antd.css'
import './itemslevel.less'

/* eslint-disable react/jsx-indent, camelcase,radix  */

const mapStateToProps = ({ eshop }) => ({
  eshop,
})

@connect(mapStateToProps)
class ItemsLevel extends React.Component {
  ShowMessage = () => {
    notification.open({
      message: 'Gift Redeemed',
      description: 'You have already redeemed the gift. Only one redemption is allowed.',
      icon: <AlertTwoTone twoToneColor="#eb2f96" />,
    })
  }

  render() {
    const {
      points,
      eshop: { giftsData, loading },
    } = this.props
    const { levels, gift_redeem } = giftsData
    const levelsList = Object.keys(levels !== undefined ? levels : {})
    return (
      <>
        {loading ? (
          <Skeleton />
        ) : (
          <div
            className="level-contaier"
            style={{
              filter: gift_redeem ? 'grayscale(1)' : 'grayscale(0)',
              pointerEvents: gift_redeem ? 'none' : '',
            }}
          >
            {levelsList.map(levelNo => {
              const { unlock_at, gifts } = levels[levelNo]
              const grayscaleFilter = points < unlock_at ? 'grayscale(1)' : 'grayscale(0)'
              const pointerEvent = points < unlock_at ? 'none' : ''
              return (
                <>
                  <h4 id={levelNo.index}>
                    {`Level ${levelNo}`}: Unlocks at {unlock_at} coins
                  </h4>
                  <div
                    className="card-container"
                    style={{ filter: grayscaleFilter, pointerEvents: pointerEvent }}
                  >
                    {gifts.map(item => {
                      return <ItemCard gift={item} id={item.index} />
                    })}
                  </div>
                  <br />
                </>
              )
            })}
          </div>
        )}
        {gift_redeem ? this.ShowMessage() : ''}
      </>
    )
  }
}

export default ItemsLevel
