import { 
  all, 
  call, 
  takeEvery, 
  put,
  select
} from 'redux-saga/effects'
import { getDirectoryData } from 'services/virtual/directory'
import { message } from 'antd'
import actions from './actions'

export function* GET_DIRECTORY_RECORD() {
  try {
    const state = yield select()
    const {
      directory: { directoryData, primaryFilter,secondaryFilter, countryFilter, classificationFilter, regionFilter,currentPage, currentRecords },
      virtualUser: { accessToken },
    } = state
    // let querystring = ''
    // filterTypeApplied.forEach(element => {
    //   switch (element) {
    //     case 'primary':
    //       querystring+= 
    //       primaryFilter.forEach(key => {
    //         querystring+= 
    //       });
    //       break;
    //     case 'secondary':
    //       break;
    //     case 'country':
    //       break;
    //     case 'region':
    //       break;
    //     case 'classification':
    //       break;
    //     default:
    //       console.log("some other filter type")
    //   }
    // });
    const querydata = yield call(getDirectoryData,{primaryFilter: primaryFilter.toString(), secondaryFilter, countryFilter,classificationFilter,regionFilter,accessToken, currentPage, currentRecords})
    if (querydata.status === 200) {
      let finalData = querydata.data
      const dataLength = Object.keys(finalData).length
      if(currentPage !== 0)
      {
          finalData = { ...directoryData , ...querydata.data}
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          directoryData: finalData,
          directoryloading: false,
          showLoadMore: dataLength >= currentRecords
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DIRECTORY_RECORD, GET_DIRECTORY_RECORD),
  ])
}
