import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import actions from 'redux/virtual/aiphotobooth/actions'
import Webcam from 'react-webcam'

const mapStateToProps = ({ aiphotobooth }) => ({ aiphotobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  capture = () => {
    const {
      dispatch,
      aiphotobooth: { currentStep },
    } = this.props
    const { countdown } = this.state
    this.setState({ startCountdown: true })
    setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 })
    }, 1000)
    setTimeout(() => {
      const imageSrc = this.webcam.getScreenshot()

      const newImg = new Image()
      newImg.src = imageSrc
      newImg.onload = () => {
        console.log("in ai booth")
        console.log(newImg.width)
        console.log(newImg.height)
      }
      const imgBlob = this.dataURLtoFile(imageSrc,"photobooth.png")
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentStep: currentStep + 1,
          imageSrc,
          webcamLoading: true,
          imageblob: imgBlob,
          imageProcessing: true
        },
      })

    dispatch({
      type: actions.UPLOAD_IMAGE,
      payload: {
        imageString: imgBlob
      },
    })
    }, countdown * 1000 + 100)
  }

  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }

  render() {
    const {
      aiphotobooth: { webcamLoading },
    } = this.props
    const { countdown, startCountdown } = this.state
    const videoConstraints = {
      width: 1000,
      height: 562,
      facingMode: 'user',
    }
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center" style={{ width: '100%', minHeight: '400px' }}>
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="countdown">{countdown}</h2> : ''}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{ width: '100%' }}
              ref={this.setRef}
              minScreenshotHeight={562}
              minScreenshotWidth={1000}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Take a selfie</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button
              type="primary"
              shape="circle"
              onClick={this.capture}
              icon={<CameraOutlined />}
              size="large"
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Capture
