import React from 'react'

class LandscapeOnly extends React.Component {

  render() {
    return (
      <img src="resources/images/rotatedevice.gif" alt="Please Rotate your device for better experience" style={{width: "100%"}} />
    )
  }
}

export default LandscapeOnly
