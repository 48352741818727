import API from '../config/api'

export async function getDirectoryData(data) {
  console.log("directory search")
  console.log(data)
  console.log(data.acccessToken)
  return API.get('searchDirectory',{
    params: {
      category: data.primaryFilter,
      industry: data.secondaryFilter,
      country: data.countryFilter,
      classification: data.classificationFilter,
      region: data.regionFilter,
      page: data.currentPage,
      size: data.currentRecords
    },
    headers: {
      Authorization: `Bearer ${data.accessToken}`,
    },
  })
}

export async function getSceneData() {
  return API.get('getPlatformTotalTimeUsuage')
}