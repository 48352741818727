import { all, put, call, takeEvery,select } from 'redux-saga/effects'
import { getLeftMenuData, getTopMenuData } from 'services/virtual/menu'
import { getMenu } from 'services/virtual/setup'
import actions from './actions'

export function* GET_DATA() {
  const state = yield select()
  const {
    virtualSettings: { isVirtualMobileView },
    virtualUser: { roles },
  } = state
  let navigationData = []
  const result = yield call(getMenu)
  if (result.status === 200) {
    navigationData = result.data
    // yield put({
    //   type: eventActions.SET_STATE,
    //   payload: {
    //     navigation: result.data
    //   }

    // })
  }
  const isExhibitor = roles.includes('ROLE_EXHIBITOR')
  const menuLeftData = yield call(getLeftMenuData,isVirtualMobileView,navigationData,isExhibitor)
  const menuTopData = yield call(getTopMenuData,isVirtualMobileView,navigationData,isExhibitor)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuLeftData,
      menuTopData,
    },
  })
}

export function* GET_INITIAL_DATA() {
  const state = yield select()
  const {
    virtualSettings: { isVirtualMobileView },
  } = state
  const menuLeftData = yield call(getLeftMenuData,isVirtualMobileView)
  const menuTopData = yield call(getTopMenuData,isVirtualMobileView)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuLeftData,
      menuTopData,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA), GET_INITIAL_DATA()])
}
