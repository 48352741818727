import { all, call, takeEvery, put } from 'redux-saga/effects'
import {
  getSavedData,
  writeData,
  deleteStallSection,
  getRoles
} from 'services/virtual/stalldesign'
import { uploadFile } from 'services/virtual/general'
import { message } from 'antd'
import actions from './actions'

export function* GET_INIT() {
  try {
    const setupData = yield call(getSavedData)
    if (Object.keys(setupData.data.stalls).length > 0) {
      const final = setupData.data.stalls
      yield put({
        type: actions.SET_STATE,
        payload: {
          stalls: final,
        },
      })
    }

    const roles = yield call(getRoles)
    if (roles.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          roles: roles.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export function* SAVE_DATA(data) {
  try {
    const result = yield call(writeData, { load: data.payload })
    if (result.status === 200) {
      const final = data.payload.scenes
      yield put({
        type: actions.SET_STATE,
        payload: {
          scenes: final,
        },
      })
      message.success('DB Updated Successfully')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_STALL_BG(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    console.log("In upload stall bg")
    console.log(data.payload)
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STALL,
        payload: {
          key: 'imageUrl',
          actualload: result.data.fileUrl,
          currentStall: data.payload.currentStall,
        },
      })

      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* DELETE_CLICKSECTION(data) {
  try {
    const result = yield call(deleteStallSection, data.payload.clickSection)
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: 'clickSections',
          actualload: data.payload.allClickSections,
          currentScene: data.payload.currentScene,
        },
      })
      message.success('Section Deleted Successfully')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SAVE_DATA, SAVE_DATA),
    takeEvery(actions.UPLOAD_STALL_BG, UPLOAD_STALL_BG),
    takeEvery(actions.DELETE_CLICKSECTION, DELETE_CLICKSECTION),
  ])
}
