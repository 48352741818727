import React from 'react'
import { Tooltip } from 'antd'
import Base64Downloader from 'react-base64-downloader'
// import { DownloadOutlined } from '@ant-design/icons'
import './visiting.less'

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
class VisitingCard extends React.Component {
  constructor(props){
    super(props)
    const { userData } = props
    const backgroundImageSrc = 'resources/images/visitingbg.jpg'
    const canvas = document.createElement('canvas')
    canvas.id = 'visitingCard'
    canvas.width = 1050
    canvas.height = 600
    const ctx = canvas.getContext('2d')

    const avatarBoxSize = 246
    let actualAvatarData = userData.avatarData
    if(userData.avatarType === "image")
    {
      actualAvatarData = userData.name
    }

    const nameLength = actualAvatarData !== null && actualAvatarData !== undefined ? actualAvatarData.length : actualAvatarData
    const approxFontSize = Math.ceil(avatarBoxSize / nameLength)
    const rectX = 60
    const rectY = 189
    const rectWidth = avatarBoxSize
    const rectHeight = avatarBoxSize

    console.log("inside making visiting card")
    console.log(nameLength)
    console.log(approxFontSize)
    console.log(Math.ceil(avatarBoxSize / nameLength))


    const image = new Image()
    image.onload = () => {
      ctx.drawImage(image, 0, 0)
      // const overlayImage = new Image()
      // overlayImage.src = userData.avatarType === 'text'
      //     ? `https://via.placeholder.com/500x500.png/${userData.avatarColor.substring(
      //         1,
      //       )}/fff?text=${userData.avatarData}`
      //     : userData.avatarData
      // overlayImage.crossOrigin = "Anonymous";
      // overlayImage.onload = () => {
      ctx.strokeStyle = '#fef033'
      ctx.lineWidth = 5;  
      // ctx.drawImage(overlayImage, 60, 189, 246, 246)
      ctx.fillStyle = userData.avatarColor;
      ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
      ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);

      /* Actual Name */
      ctx.font = "50px Arial";
      ctx.fillStyle = "#0190fe";
      ctx.fillText(userData.name, 365, 230);

      ctx.font = "40px Arial";
      ctx.fillText(userData.email, 365, 290);
      ctx.fillText(userData.number, 365, 340);

      /* Avatar Data inside Rectangle */
      ctx.font = `${approxFontSize}px Arial`;
      ctx.fillStyle = "white";
      ctx.textAlign="center"; 
      ctx.textBaseline = "middle";
      ctx.fillText(actualAvatarData, rectX+(rectWidth/2),rectY+(rectHeight/2));


      const finalImage = canvas.toDataURL()
      this.setState({visitingCard: finalImage})


      // }
    }
    image.src = backgroundImageSrc
    image.crossOrigin = "Anonymous";

    
  }

  state = {
    visitingCard:"resources/images/visitingbgloading.jpg"
  }

 

  render() {
    const { userData:{name} } = this.props
    const {visitingCard} = this.state
    return (
      <>
        <img src={visitingCard} style={{width:"100%"}} alt="visitingcard" crossOrigin="Anonymous" />
        <div className="visitingdownload">
          <Base64Downloader base64={visitingCard} downloadName={name}>
            <Tooltip placement="bottomLeft" title="Downloading Visiting Card">
              <img
                src="./resources/images/downloadicon.png"
                alt="paynow"
                style={{position:"absolute",left:"85%",top:"5%",width:"20px",height:"20px", cursor: "pointer"}}
              />
            </Tooltip> 
          </Base64Downloader>
        </div>
       
      </>
      // <Space>
      //   <Row>
      //     <Col span={10}>
      //       <Avatar
      //         src={userData.avatar}
      //         shape="square"
      //         size={80}
      //         style={{ borderRadius: '8px' }}
      //       />
      //     </Col>
      //     <Col span={12}>
      //       <Row justify="space-around" align="middle">
      //         <Col span={24}>
      //           <h4>
      //             <b>{userData.name}</b>
      //           </h4>
      //         </Col>
      //       </Row>
      //       <Row justify="space-around" align="middle">
      //         <Col span={24}>
      //           <h6>{`${userData.designation},`}</h6>
      //         </Col>
      //       </Row>
      //       <Row justify="space-around" align="middle">
      //         <Col span={24}>
      //           <h6>{userData.organization}</h6>
      //         </Col>
      //       </Row>
      //     </Col>
      //     <Col span={2}>
      //       <Button type="primary" ghost shape="circle" icon={<DownloadOutlined />} onClick={this.downloadVisiting} size="small" />
      //     </Col>
      //   </Row>
      // </Space>
    )
  }
}

export default VisitingCard
