import React from 'react'
import { Badge, Avatar } from 'antd';

class ChatHeader extends React.Component {

  render() {
    const {avatarType, avatarData, loginStatus, shape, size, avatarColor} = this.props
    return (
      <>
        {avatarType === "image"
        ?
          <Badge status={loginStatus ? "success" : "warning"}>
            <Avatar
              src={avatarData}
              border
              borderColor="white"
              shape={shape}
              size={size}
            />
          </Badge>
        :
          <Badge status={loginStatus ? "success" : "warning"}>
            <Avatar
              style={{
                backgroundColor: avatarColor,
                verticalAlign: 'middle',
              }}
              size={size}
              shape={shape}
            >
              {avatarData}
            </Avatar>
          </Badge>
        }

      </>
    )
  }
}

export default ChatHeader
