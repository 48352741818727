const actions = {
  SET_STATE: 'contest/SET_STATE',
  FETCH_CONTEST_ENTERIES: 'contest/FETCH_CONTEST_ENTERIES',
  FETCH_MORE_CONTEST_ENTERIES: 'contest/FETCH_CONTEST_ENTERIES',
  ENTRY_UPLOAD: 'contest/ENTRY_UPLOAD',
  REMOVE_LIKE: 'contest/REMOVE_LIKE',
  ADD_LIKE: 'contest/ADD_LIKE',
}

export default actions
