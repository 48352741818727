const actions = {
  SET_STATE: 'streamfeature/SET_STATE',
  SET_MESSAGE: 'streamfeature/SET_MESSAGE',
  SEND_MESSAGE: 'streamfeature/SEND_MESSAGE',
  SET_QUESTION: 'streamfeature/SET_QUESTION',
  SET_POLLING: 'streamfeature/SET_POLLING',
  FETCH_MESSAGES: 'streamfeature/FETCH_MESSAGES',
  FETCH_QUESTIONS: 'streamfeature/FETCH_QUESTIONS',
  SEND_QUESTION: 'streamfeature/SEND_QUESTION',
  SEND_CREATE_POLL: 'streamfeature/SEND_CREATE_POLL',
  ALLOW_QANDA: 'streamfeature/ALLOW_QANDA',
  ALLOW_POLL: 'streamfeature/ALLOW_POLL',
  HIDE_POLL: 'streamfeature/HIDE_POLL',
  ANSWER_POLL: 'streamfeature/ANSWER_POLL',
  FETCH_POLL: 'streamfeature/FETCH_POLL',
  EMAIL_NOTE: 'streamfeature/EMAIL_NOTE',
  RAISE_HAND: 'streamfeature/RAISE_HAND',
  REVOKE_HAND: 'streamfeature/REVOKE_HAND',
  SET_RAISE_HAND: 'streamfeature/SET_RAISE_HAND',
  FETCH_RAISE_HANDS: 'streamfeature/FETCH_RAISE_HANDS',
  FETCH_ALL_RAISE_HANDS: 'streamfeature/FETCH_ALL_RAISE_HANDS',
  APPROVE_RAISE_HAND: 'streamfeature/APPROVE_RAISE_HAND',
  VALIDATE_RAISE_HAND: 'streamfeature/VALIDATE_RAISE_HAND',
  RELEASE_VMIX_CALL: 'streamfeature/RELEASE_VMIX_CALL'
}

export default actions
