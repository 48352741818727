import React from 'react'
import { Row, Col, Empty } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/contest/actions'

import '../contest.less'
import '../heart.less'

const mapStateToProps = ({ contest }) => ({ contest })

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-element-interactions
 */
@connect(mapStateToProps)
class EntryViewer extends React.Component {
  constructor(props) {
    super(props)
    const {
      contest: { currentPage },
    } = props
    props.dispatch({
      type: actions.FETCH_CONTEST_ENTERIES,
      payload: {
        currentPage,
      },
    })
  }

  openFile = selectedIndex => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentView: 'slideshow',
        currentIndex: selectedIndex,
      },
    })
  }

  render() {
    const {
      contest: { theImageSet, contestEnteries },
    } = this.props
    const galleryGridSize = 6
    let theIndex = 0
    const imageMap = theImageSet.map(theEntry => {
      console.log("let's check entry")
      console.log(theEntry)
      const actualEntry = contestEnteries[theImageSet[theIndex].originalIndex]
      theIndex += 1
      return (
        <>
          <Col span={galleryGridSize}>
            <img
              src={theEntry.thumbnail}
              style={{ width: '100%' }}
              alt={theEntry.description}
              onClick={() => {
                this.openFile(theIndex)
              }}
            />
            <p className="white-text">{actualEntry.uploaded_by_name}</p>
            <br />
          </Col>
          <Col span={1} />
        </>
      )
    })
    return (
      <>
        {theImageSet.length !== 0 ? (
          <>
            <br />
            <Row>
              <Col span={6} />
              <Col span={12} className="text-center">
                <h2 className="white-text">All enteries</h2>
              </Col>
              <Col span={6} />
            </Row>
            <br />
            <Row justify="space-around" align="middle" className="galleryZone">
              <Col span={2} />
              {imageMap}
              <Col span={1} />
            </Row>
            <br />
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <h2>No Enteries Yet</h2>
                <span style={{ color: '#d3d3d3' }}>
                  Be the first one to submit the entry and increase your chance to win.
                </span>
              </>
            }
          />
        )}
      </>
    )
  }
}

export default EntryViewer
