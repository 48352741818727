import React from 'react'
import { connect } from 'react-redux'
import Ticker from 'react-ticker'
import actions from 'redux/virtual/chat/actions'
import './ticker.less'

const mapStateToProps = ({ Chat }) => ({ Chat })
@connect(mapStateToProps)
class TickerNotification extends React.Component {
  componentDidUpdate() {
    const {dispatch, Chat:{currentNotification}} = this.props
    console.log("atleast here")
    if(currentNotification !== '')
    {
      setTimeout(() => {
        console.log("not here")
        dispatch({
          type: actions.SET_STATE,
          payload: {
            currentNotification: ''
          }
        })
      }, 20000)
    }
  }

  render() {
    const {Chat: {currentNotification}} = this.props
    return (
      currentNotification !== '' 
      ?
        <Ticker mode="smooth" offset="run-in">
          {() => (
            <>
              <h1>{currentNotification}</h1>
            </>
          )}
        </Ticker>
      :
        ''
    )
  }
}

export default TickerNotification