import React from 'react'
import { FormattedMessage } from 'react-intl'
/* eslint-disable */ 
const cleanData = (items) => items
  // Filter out the object with the specified key
  .filter(({ key }) => key !== "8bf0da-8b2b-fa5b-482d-28dc4f75044")
  .map(({ children, ...rest }) => {
    // If children exist and is not an empty array, process its children recursively
    if (children && children.length > 0) {
      return { ...rest, children: cleanData(children) };
    } else if (children) {
      // Remove children if it's an empty array
      return { ...rest };
    }
    return rest;
  });
export async function getLeftMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }

  console.log('is logout?')
  console.log(isLogout)

  return [
    // {
    //   title: <FormattedMessage id="virtualMenu.back" />,
    //   key: 'back',
    //   icon: 'icmn icmn-undo2',
    // },
    {
      title: <FormattedMessage id="virtualMenu.home" />,
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: <FormattedMessage id="virtualMenu.agenda" />,
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    // {
    //   title: <FormattedMessage id="virtualMenu.auditorium" />,
    //   key: 'auditorium',
    //   icon: 'icmn icmn-library',
    // },
    {
      title: <FormattedMessage id="virtualMenu.feedback" />,
      key: 'feedback',
      icon: 'icmn icmn-file-text',
    },
    // {
    //   title: <FormattedMessage id="virtualMenu.chat" />,
    //   key: 'sidebar',
    //   icon: 'icmn icmn-bubbles2',
    // },
    {
      title: <FormattedMessage id="virtualMenu.navigation" />,
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData!==undefined&&cleanData(navigationData),
    },

    // {
    //   title: <FormattedMessage id="virtualMenu.briefcase" />,
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.helpdesk" />,
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.mypoints" />,
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    // {
    //   title: <FormattedMessage id="virtualMenu.speakers" />,
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.meetings" />,
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.leaderboard" />,
    //   key: 'leaderBoard',
    //   icon: 'icmn icmn-users',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.eshop" />,
    //   key: 'eShop',
    //   icon: 'icmn icmn-cart',
    // },
    // theDirectory,
    // isLogout,
    thefinalLeg,
  ]
}
export async function getTopMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  console.log('in menu top')
  console.log(isVirtualMobileView,navigationData)
  console.log('navigationData',navigationData)
  
  
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }
  return [
    // {
    //   title: <FormattedMessage id="virtualMenu.back" />,
    //   key: 'back',
    //   icon: 'icmn icmn-undo2',
    // },
    {
      title: <FormattedMessage id="virtualMenu.home" />,
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: <FormattedMessage id="virtualMenu.agenda" />,
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: <FormattedMessage id="virtualMenu.feedback" />,
      key: 'feedback',
      icon: 'icmn icmn-file-text',
    },
    // {
    //   title: <FormattedMessage id="virtualMenu.auditorium" />,
    //   key: 'auditorium',
    //   icon: 'icmn icmn-library',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.coffee" />,
    //   key: 'coffee',
    //   icon: 'icmn icmn-file-text',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.chat" />,
    //   key: 'sidebar',
    //   icon: 'icmn icmn-bubbles2',
    // },
    {
      title: <FormattedMessage id="virtualMenu.navigation" />,
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData!==undefined&&cleanData(navigationData),
    },

    // {
    //   title: <FormattedMessage id="virtualMenu.briefcase" />,
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.helpdesk" />,
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.mypoints" />,
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    // {
    //   title: <FormattedMessage id="virtualMenu.speakers" />,
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.meetings" />,
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.leaderboard" />,
    //   key: 'leaderBoard',
    //   icon: 'icmn icmn-users',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.eshop" />,
    //   key: 'eShop',
    //   icon: 'icmn icmn-cart',
    // },
    // Object.keys(theDirectory).length !== 0 ? theDirectory : '',
    thefinalLeg,
    // isLogout,
  ]
}
