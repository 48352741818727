import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { getAllTasks, updateSectionScore } from 'services/virtual/pointsredeem'
import { message } from 'antd'
import actions from './actions'
import eventActions from '../event/actions'

export function* GET_TASKS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const taskData = yield call(getAllTasks, { token: accessToken })

    if (taskData.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          totalPoints: taskData.data.totalPoints,
          myPoints: taskData.data.userScore,
          tasks: taskData.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ALLOT_POINTS(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
      virtualEvent: { sectionPointsClaimed },
    } = state
    yield call(updateSectionScore, { sectionID: data.payload.widgetID, token: accessToken })
    const tempsectionPointsClaimed = sectionPointsClaimed
    tempsectionPointsClaimed.push(data.payload.widgetID)
    yield put({
      type: eventActions.SET_STATE,
      payload: {
        hasWidgetPoints: false,
        widgetID: '',
        sectionPointsClaimed: tempsectionPointsClaimed,
      },
    })
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TASKS, GET_TASKS),
    takeEvery(actions.ALLOT_POINTS, ALLOT_POINTS),
  ])
}
