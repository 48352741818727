/* eslint-disable */

import React from 'react'
import { Popover, Row, Col, Divider, Button, Avatar, Space } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'
import { PlayCircleFilled } from '@ant-design/icons'
import SpeakerMiniProfile from '../SpeakerMiniProfile'
import './session.less'
const mapStateToProps = ({ router }) => ({ router })

@connect(mapStateToProps)
class SessionList extends React.Component {
  initiateSession = (event) => {
    const { dispatch, agendaType } = this.props
    const id = event.target.getAttribute('data-id')
    const scene = event.target.getAttribute('data-room')
    const url = event.target.getAttribute('data-url')

    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })

    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
        callScene: agendaType !== "local"
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  getAgendaItem = (item, arrindex, arrLen) => {
    const { isVirtualMobileView } = this.props
    const theSpeakers = Object.entries(item[1].speakers).map(([_, speaker]) => (
      <Popover
        key={speaker.id} // Assuming each speaker has a unique ID
        overlayClassName="speakerpopover"
        content={<SpeakerMiniProfile speakerData={speaker} />}
      >
        <Avatar
          src={speaker.imageUrl}
          shape="square"
          size={75}
          style={{ borderRadius: '10px' }}
        />
      </Popover>
    ))

    return (
      <div className='sessionItem' key={item[0]}>

        <Row>
          <Col span={8} style={{ border: '1px solid white', borderTop: 'none', borderRight: 'none' }}>
            <Row>
              <Col span={24} className="sessionTitle">
                <p>{item[1].title}</p>
              </Col>
            </Row>
          </Col>
          <Col span={8} style={{ border: '1px solid white', borderTop: 'none', borderRight: 'none' }}>
            <Row className="sessionContent text-left">
              <Col span={24}>
                <p dangerouslySetInnerHTML={{ __html: item[1].description }} />
              </Col>
            </Row>
            <Row className="speakerProfile">
              <Col span={24}>
                <Space>{theSpeakers}</Space>
              </Col>
            </Row>
          </Col>
          <Col span={8} style={{
            border: '1px solid white',
            borderRight: '1px solid white',
            borderTop: item[1]?.showJoinBtn?'1px solid white':'none',
            borderBottom: arrindex === arrLen - 1 ? '1px solid white' : item[1]?.showJoinBtn ? 'none' : 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Row gutter={0} className="sessionActions" justify="start" align="middle">
              <Col xs={24} md={10} lg={8} xl={6}>
                <Button
                  type="primary"
                  shape="round"
                  data-url={item[1].url}
                  data-id={item[0]}
                  data-room={item[1].assignedRoom}
                  icon={<PlayCircleFilled />}
                  onClick={this.initiateSession}
                  size="medium"
                  className='joinButton'
                  style={{ display: item[1]?.showJoinBtn ? 'block' : 'none' }}
                >
                  Watch Now
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { sessions } = this.props
    const uniqueUrls = new Set()

    // Adding showJoinBtn property based on unique URLs
    const enhancedSessions = Object.entries(sessions).map(([key, session]) => {
      const isUniqueUrl = !uniqueUrls.has(session.url)
      uniqueUrls.add(session.url) // Add the URL to the set
      return { ...session, showJoinBtn: isUniqueUrl } // Return the session with showJoinBtn
    })
    console.log('enhancedSessions', enhancedSessions);

    const showcase = enhancedSessions.map((session, index) => {
      return this.getAgendaItem([session.id, session], index, enhancedSessions?.length) // Assuming session has id property
    })

    return <div>{showcase}</div>
  }
}

export default SessionList
