import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
// import styles from '../../style.module.scss'

const mapStateToProps = ({ router }) => ({ router })
@connect(mapStateToProps)
class MeetingInvite extends React.Component {
  startMeeting = () => {
    const {
      id,
      dispatch,
      router: {
        location: { pathname, query },
      },
    } = this.props
    let querySelectors = '?'
    let counter = 0
    const totalQueries = Object.keys(query).length
    Object.entries(query).map(key => {
      querySelectors += `${key[0]}=${key[1]}`
      if (counter < totalQueries - 1) {
        querySelectors += `&`
      }
      counter += 1
      return ''
    })
    dispatch(push(`${pathname}${querySelectors}&meeting=${id}`))
  }

  render() {
    return (
      <div className="text-center">
        <h3>Hey Lets get on a call together</h3>
        <Button type="primary" onClick={this.startMeeting}>
          Join Meeting
        </Button>
      </div>
    )
  }
}

export default MeetingInvite
