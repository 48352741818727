import React from 'react'

import '../leaderboard.less'

/* eslint-disable react/jsx-indent, camelcase */

class PointingContainer extends React.Component {
  render() {
    const { points } = this.props
    return (
      <div>
        <span className="user_points">
          <div className="pointsContainer">
            {points > 0 ? (
              <span className="pointArea">
                {points} <img src="resources/images/badge.png" alt="coin" />
              </span>
            ) : (
              'NA'
            )}
          </div>
        </span>
      </div>
    )
  }
}

export default PointingContainer
