import { all, call, takeEvery, select, put } from 'redux-saga/effects'
import { updateWidgetAnalytics } from 'services/virtual/event'
import { uploadPhotoboothImage,getAllPhotoboothUploads } from 'services/virtual/general'
import { message } from 'antd'
import actions from './actions'

export function* PHOTOBOOTH_ANALYTICS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(updateWidgetAnalytics, { widgetid: '1234', token: accessToken })
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_PHOTOBOOTH_IMAGE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(uploadPhotoboothImage, { imageData: data.payload.imageString, token: accessToken })

  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* FETCH_PHOTOBOOTH_ENTERIES() {
  try {
    const result = yield call(getAllPhotoboothUploads)
    console.log("checking results")
    console.log(result)
    if(result.status === 200){
      const allImages = []
      result.data.uploads.forEach(element => {
        allImages.push(
          {
            original: element.file_url,
            thumbnail: element.file_url,
          }
        )
        return ''
      });
      console.log("checking obtained results")
      console.log(allImages)
      yield put({
        type: actions.SET_STATE,
        payload: {
          galleryData: allImages
        }
      })
    }
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.PHOTOBOOTH_ANALYTICS, PHOTOBOOTH_ANALYTICS),
    takeEvery(actions.UPLOAD_PHOTOBOOTH_IMAGE, UPLOAD_PHOTOBOOTH_IMAGE),
    takeEvery(actions.FETCH_PHOTOBOOTH_ENTERIES, FETCH_PHOTOBOOTH_ENTERIES)
  ])
}
