const actions = {
  GET_INIT: 'stalldesign/GET_INIT',
  SET_STATE: 'stalldesign/SET_STATE',
  SET_DEFAULT: 'stalldesign/SET_DEFAULT',
  GET_DEFAULT: 'stalldesign/GET_DEFAULT',
  ADD_SCENE: 'stalldesign/ADD_SCENE',
  SET_STALL: 'stalldesign/SET_SCENE',
  SAVE_DATA: 'stalldesign/SAVE_DATA',
  UPLOAD_STALL_BG: 'stalldesign/UPLOAD_STALL_BG',
  UPLOAD_FILES: 'stalldesign/UPLOAD_FILES',
  UPLOAD_SCENE_LOGO: 'stalldesign/UPLOAD_SCENE_LOGO',
  DELETE_CLICKSECTION: 'stalldesign/DELETE_CLICKSECTION',
  DELETE_SESSION: 'stalldesign/DELETE_SESSION',
}

export default actions
