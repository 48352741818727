import React from 'react'
import { Card } from 'antd'
import { connect } from 'react-redux'
import StartScreen from './LayoutComponents/StartScreen'
// import Selection from './LayoutComponents/Selection'
import Capture from './LayoutComponents/Capture'
// import UploadImage from './LayoutComponents/UploadImage'
import Download from './LayoutComponents/Download'
// import Background from './LayoutComponents/Background'
// import Showcase from './LayoutComponents/Showcase'
// import Sticker from './LayoutComponents/Sticker'
import './booth.less'

const steps = [
  <StartScreen />,
  // <Selection />,
  <Capture />,
  // <UploadImage />,
  // <Showcase />,

  // <Background />,
  // <Sticker />,
  <Download />,
]

const mapStateToProps = ({ photobooth }) => ({ photobooth })
@connect(mapStateToProps)
class Booth extends React.Component {
  render() {
    const {
      photobooth: { currentStep },
    } = this.props
    return (
      <div className="photobooth text-center">
        <Card bordered={false} className="boothDesign">
          <ul className="circles">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          {steps[currentStep]}
        </Card>
      </div>
    )
  }
}

export default Booth
