const actions = {
  SET_STATE: 'stallconfigurator/SET_STATE',
  SET_STALL: 'stallconfigurator/SET_STALL',
  EDIT_BRAND_LOGO: 'stallconfigurator/EDIT_BRAND_LOGO',
  GET_STALL_OPTIONS: 'stallconfigurator/GET_STALL_OPTIONS',
  STALL_UPLOAD: 'stallconfigurator/STALL_UPLOAD',
  INITIATE_SCENE: 'stallconfigurator/INITIATE_SCENE',
  SAVE_STALL: 'stallconfigurator/SAVE_STALL'
}

export default actions
