import React from 'react'
import capitalizeFirstLetters from 'capitalize-first-letters';

class DescriptionFormatter extends React.Component {

  render() {
    const { designation, organization } = this.props
    return (
      <>
        {capitalizeFirstLetters(designation)}
        <br />
        {capitalizeFirstLetters(organization)}
      </>
    )
  }
}

export default DescriptionFormatter
